import { io } from "socket.io-client";

const socket = io("https://dev.services.mifotra.gov.rw", {
  transports: ["websocket"],
});

socket.connect();

socket.on("connect", () => {
  console.log("*********Connected to the socket");
});

socket.on("disconnect", (e) => {
  console.log("Disconnected to the socket");
});

export default socket;
